/**
 * 示例-自定义组件-数字格式化
 * luxinwen
 * 2023-02
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-number 数字格式化</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-number v-model="value"></sp-number>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-number v-model="value"&gt;&lt;/sp-number&gt;</pre>
          <p>基本用法。可以使用 v-model 双向绑定数据。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-number v-model="value" decimals="2"></sp-number>
          <Divider orientation="left" plain>小数位数</Divider>
          <pre>&lt;sp-number v-model="value" decimals="2"&gt;&lt;/sp-number&gt;</pre>
          <p>设置属性 <span class="text-red">decimals</span>，可以控制数值的小数位数。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-number v-model="value" prefix="$"></sp-number>
          <Divider orientation="left" plain>前缀符号</Divider>
          <pre>&lt;sp-number v-model="value" prefix="$"&gt;&lt;/sp-number&gt;</pre>
          <p>设置属性 <span class="text-red">prefix</span>，可以添加前缀符号。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-number v-model="value" effect></sp-number>
          <p><Button type="info" @click="showEffect">查看效果</Button></p>
          <Divider orientation="left" plain>过渡动画</Divider>
          <pre>&lt;sp-number v-model="value" effect&gt;&lt;/sp-number&gt;</pre>
          <p>设置属性 <span class="text-red">effect</span>，可以显示数值变化的过渡效果。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '要格式化的数值，可以使用 v-model 双向绑定数据',
            type: 'String, Number',
            default: ''
          },
          'decimals': {
            tips: '小数点后面位数',
            type: 'String, Number',
            default: '0'
          },
          'prefix': {
            tips: '前缀符号',
            type: 'String',
            default: ''
          },
          'separator': {
            tips: '千分位符号',
            type: 'String',
            default: ','
          },
          'effect': {
            tips: '是否显示数值变化的过渡效果',
            type: 'Boolean',
            default: 'false'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        value: 123456.78
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    },
    methods: {
      showEffect() {
        this.value += 1000;
      }
    }
  };
</script>